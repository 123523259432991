import DialogBox from '@/component/dialog'
import refund_hange_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import consumer_web_policyConfig_carPolicyList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_carPolicyList'
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import consumer_web_policyConfig_delTravelPolicyByBusinessType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_delTravelPolicyByBusinessType'

export default {
  name: "evectionCar",
  data() {
    return {
      loadingSwitch: true,
      positionVoArr:[],
      dataTest: {
        workChoice: [0,1],
        workChoiceOption: [
          {
            value: 0,
            label: '接送机场/车站'
          },
          {
            value: 1,
            label: '打车'
          }
        ],
        Choice:[
          [
            [
              {
                name:'接送机场/车站'
              }
            ],
            [
              {
                name:'车型限制',
                type:1,
                model:[0],
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'经济型'
                  },
                  {
                    value:2,
                    label:'舒适型'
                  },
                  {
                    value:3,
                    label:'商务型'
                  },
                  {
                    value:4,
                    label:'豪华型'
                  },
                ],
              },
              {
                name:'费用限制',
                type:2,
                model:0,
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'设置上限'
                  }
                ],
                maxNum:100,
              },
              {
                name:'强制匹配出行行程',
                type:2,
                model: 0,
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'强制'
                  }
                ],
              },
              {
                name:'预订最低价产品',
                type:2,
                model: 0,
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'预订最低价'
                  }
                ],
              },
            ]
          ],
          [
            [
              {
                name:'打车'
              }
            ],
            [
              {
                name:'车型限制',
                type:1,
                model:[0,1],
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'经济型'
                  },
                  {
                    value:2,
                    label:'舒适型'
                  },
                  {
                    value:3,
                    label:'商务型'
                  },
                  {
                    value:4,
                    label:'豪华型'
                  }
                ],
              },
              {
                name: '费用限制',
                type: 2,
                model: 0,
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'设置上限'
                  }
                ],
                maxNum:100,
              },
              {
                name:'预订最低价产品',
                type:2,
                model:0,
                modelOption:[
                  {
                    value:0,
                    label:'不限'
                  },
                  {
                    value:1,
                    label:'预订最低价'
                  }
                ],
              },
            ]
          ]
        ]
      },
      policyList:[
        {
          id:0,
          level:['部门主管'],
          workChoice: [0,1],
          OneModel:[0,1,2],
          OneLimit:1,
          OneMaxNum:100,
          OneLowPrice:1,
          TwoModel:[0,1,2,3],
          TwoLimit:1,
          TwoMaxNum:100,
          TwoTrip:1,
          TwoLowPrice:1
        },
        {
          id:0,
          level:['员工'],
          workChoice: [0,1],

          OneModel:[1,2],
          OneLimit:0,
          OneMaxNum:100,
          OneLowPrice:0,

          TwoModel:[1,2,3],
          TwoLimit:0,
          TwoMaxNum:100,
          TwoTrip:0,
          TwoLowPrice:0
        }
      ],
      dataIndex:null,
      useCarSwitch: true,
      default:{
        level:['员工'],
        workChoice: [0,1],
        OneModel:[0],
        OneLimit:0,
        OneMaxNum:100,
        OneLowPrice:0,
        TwoModel:[0],
        TwoLimit:0,
        TwoMaxNum:100,
        TwoTrip:0,
        TwoLowPrice:0
      }
    }
  },
  components: { DialogBox, },
  created() {
    this._refund_hange_policy_get()
  },
  activated(){
    this.loadingSwitch = true;
    this.getData();
  },
  mounted() {

  },
  methods: {
    carReasonFunction(index) {
      this.policyList[index].carReason = this.policyList[index].carReason ? 1 : 0;
      return true;
    },
    getData(){
      consumer_web_policyConfig_carPolicyList().then((res) => {
        this.policyList = res.datas.policyList;
        this.loadingSwitch = false;
      });
      consumer_web_company_checkCompanyUseCar().then(res => {
        if (res.datas.useCar == 0) {
          this.useCarSwitch = false;
          this.$message.error('企业暂未开通用车功能，开通请咨询在线客服');
        }
      })
    },
    clickDefault() {
      if (this.useCarSwitch) {
        this.$router.push({
          name: 'admin-evection-car-policy-add',
        })
      } else {
        this.$message.error('企业暂未开通用车功能，开通请咨询在线客服');
      }
    },
    clickDel(item,index) {
      this.$confirm('确定删除此政策吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingSwitch = true;
        consumer_web_policyConfig_delTravelPolicyByBusinessType({ id: item.id, businessType: 4 })
            .then(res => {
              this.getData()
              this.$message({
                message: '删除成功',
                type: 'success'
              });
            })
            .catch(ref => {
              this.$message.error('删除失败');
            })
      }).catch(() => {
      });
    },
    clickEdit(item,index) {
      this.$router.push({
        name: 'admin-evection-car-policy-edit',
        query: {
          id: item.id,
          Policytype: item.carPolicyType,
        }
      })
    },
    /**
     * 
     * @param {*} type 1:机票 2:火车票
     */
    // _getDialogShow(type) {
    //   this.dialogShow = true
    //   this.dialogType = type
    //   let _data = this.formData
    //   if (type === 1) {
    //     _data.refund = this.detail.airRefund
    //     _data.ticket = this.detail.airChange
    //   } else {
    //     _data.refund = this.detail.carRefund
    //     _data.ticket = this.detail.carChange
    //   }
    // },
    /**
     *  datas: 0:不限, 10:出差行程变化, 20:天气因素, 30:定错机票, 40:定错车次
     */
    _refund_hange_policy_get() {
      refund_hange_policy_get().then(res => {
        console.log(res);
        this.detail = res.datas;
      })
    }
  }
}